var MegaMenuDepartments = React.createClass({
  onClick: function (department, e) {
    this.props.setDepartment(department);
  },


  onHover: function(department, e) {
    e.preventDefault();
    var RThis = this;
    var node = this.getDOMNode();
    var timer = setTimeout(function () {
      RThis.props.setDepartment(department);
    }, 250);
    jQuery(node).mouseout(function () {
      clearTimeout(timer);
    });
  },

  render: function () {
    var departments = this.props.departments.map(function (department) {

      var key = 'department-' + department.tid
        , active = (this.props.filter.department === department)
        , className = (active ? 'active' : '')
        , boundClick = this.onClick.bind(this, department)
        , boundHover = this.onHover.bind(this, department);

      return (
        <li key={key}
          className={className}
          onMouseOver={boundHover}>{department.name}</li>
      );
    }.bind(this));

    return (
      <div className="departments">
        <div className="container">
          <ul>
            {departments}
          </ul>
        </div>
      </div>
    );
  }
});
