var MegaMenuSection = React.createClass({
  onClick: function (section, e) {
    this.props.setSection(section);
  },

  onHover: function(section, e) {
    e.preventDefault();
    var RThis = this;
    var node = this.getDOMNode();
    var timer = setTimeout(function () {
      RThis.props.setSection(section);
    }, 250);
    jQuery(node).mouseout(function () {
      clearTimeout(timer);
    });
  },

  render: function () {
    // Based on the currently filtered department, render any sections that have
    // content available.
    var departmentContent = this.props.content.filter(function (content) {
      return (jQuery.inArray(this.props.filter.department.tid, content.departments) !== -1);
    }.bind(this));

    var visibleSections = departmentContent.reduce(function (all, content) {
      if (all.indexOf(content.section) === -1) { all.push(content.section); }
      return all;
    }, []);

    var sections = visibleSections.map(function (section) {
      var active = (this.props.filter.section === section)
        , className = (active ? 'active' : '')
        , boundClick = this.onClick.bind(this, section)
        , boundHover = this.onHover.bind(this, section)
        , path;

      if(section == 'Blog') {
        path = '/blog';
      } else if(section == 'Network') {
        path = '/networks/ceiia-networks';
      } else if(section == 'Workshop') {
        path = '/workshops';
      }

      return (
        <li key={section}
          className={className}
          onClick={boundClick}
          onMouseOver={boundHover}><a href={path}>{section}</a></li>
      );
    }.bind(this));

    return (
      <ul className="sections">
        {sections}
      </ul>
    );
  },
});
