var MegaMenu = React.createClass({

  getInitialState: function () {

    var state = {
      departments: Drupal.settings.centers_megamenu.departments,
      content: Drupal.settings.centers_megamenu.blog,
      filter: {
        section: 'Blog'
      }
    };

    // gets first department
    state.filter.department = state.departments[0];
    return state;
  },

  setDepartment: function (department) {
    var newFilter = {
      department: department,
      section: this.state.filter.section
    };
    this.setState({ filter: newFilter });
  },

  setSection: function (section) {
    var newFilter = {
      department: this.state.filter.department,
      section: section
    };
    this.setState({ filter: newFilter });
  },

  render: function () {
    var className = 'background ' + this.state.filter.department.name;
    return (
      <div className={className}>
        <MegaMenuDepartments
          departments={this.state.departments}
          filter={this.state.filter}
          setDepartment={this.setDepartment} />
        <div className="bottom container">
          <MegaMenuSection
            content={this.state.content}
            filter={this.state.filter}
            setSection={this.setSection} />
          <MegaMenuContent
            content={this.state.content}
            filter={this.state.filter} />
        </div>
      </div>
    );
  },
});

jQuery(document).ready(function () {
  React.render(
    React.createElement(MegaMenu, null),
    document.getElementById('react-megamenu')
  );
});
