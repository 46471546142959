var MegaMenuContent = React.createClass({
  render: function () {
    var departmentContent = this.props.content.filter(function (content) {
      return (jQuery.inArray(this.props.filter.department.tid, content.departments) !== -1);
    }.bind(this));

    var sectionContent = departmentContent.filter(function (content) {
      return (this.props.filter.section === content.section);
    }.bind(this));

    var content = sectionContent.map(function (content) {
      var key = 'node-' + content.nid;
      return (
        <li key={key}>
          <div className="post">
            <a href={content.path}>
              <img src={content.image} />
              <h4>{content.title}</h4>
              <span className="timestamp">{content.date}</span>
            </a>
          </div>
        </li>
      );
    });

    return (
      <ul className="posts">
        {content}
      </ul>
    );
  },
});
